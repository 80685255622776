import React from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';
import ReactMoment from 'react-moment';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import MuiLink from '@material-ui/core/Link';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import { items } from '../../utils';
import Layout from '../../components/Layout';
import SEO from '../../components/SEO';
import SundayLocation from '../../components/SundayLocation';
import ShareButtons from '../../components/ShareButtons';

export const query = graphql`
  query {
    api {
      upcomingEvents(calendar: "sunday", first: 1) {
        edges {
          node {
            id
            start {
              date
              dateTime
            }
          }
        }
      }
    }
  }
`;

const Page = ({ data }) => {
  const upcomingSunday = items(data.api.upcomingEvents);

  return (
    <Layout>
      <SEO title="Sunday Meeting" />
      <Box textAlign="center" mb={6}>
        <Typography variant="h1">Sunday Meeting</Typography>
      </Box>

      <Box mb={8}>
        <Divider />
      </Box>

      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Box mb={4}>
            <Typography variant="h4">Our Gathering</Typography>
          </Box>

          <Box mb={4}>
            <Typography component="div" variant="body1">
              <Grid container spacing={1}>
                <Grid item xs={12} sm={3}>
                  <strong>Next date:</strong>{' '}
                </Grid>
                <Grid item xs={12} sm={9}>
                  {upcomingSunday.length > 0 && (
                    <ReactMoment format="dddd, MMMM D, YYYY">
                      {upcomingSunday[0].start.dateTime}
                    </ReactMoment>
                  )}
                </Grid>
              </Grid>
            </Typography>
          </Box>

          <Box mb={4}>
            <Typography variant="body1">
              We meet as a church each Sunday morning at 10:30am for a time of
              singing, preaching, encouragement, and sharing, followed by a
              meal.
            </Typography>
          </Box>

          <Box mb={4}>
            <Typography variant="body1">
              Read more about our heart toward our meetings{' '}
              <MuiLink component={Link} to="/about#our-meetings">
                here
              </MuiLink>
              .
            </Typography>
          </Box>

          <Box mb={4}>
            <Button
              href="https://goo.gl/maps/JWXs7TqEQ1Zx2CDQ7"
              color="secondary"
              endIcon={<ChevronRightIcon />}
            >
              Get Directions
            </Button>
          </Box>

          <Box mb={4}>
            <ShareButtons title="Sunday Meeting" />
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box mb={4}>
            <Typography variant="h4">Meeting Location</Typography>
          </Box>

          <SundayLocation />
        </Grid>
      </Grid>
    </Layout>
  );
};

Page.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Page;
